.room-code-sticker {
  position: absolute;
  top: calc(20px + env(safe-area-inset-top));
  right: 0;
  background-color: #ffffff;
  color: #000000;
  padding: 1.45rem 1.513rem;
  border-radius: 1.1rem;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0rem 3rem;

  width: 11.1rem;
  text-align: center;

}

.room-code-sticker .qr-code {
  height: auto;
  margin: 0 auto;
  width: 100%;
  /* float: left; */
}

.room-code-sticker .room-code {
  /* float: left; */
  margin-top: 0.62rem;
  font-size: 0.89rem;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.room-code-sticker .room-code b {
  font-size: 1.5rem;
  font-weight: normal;
  display: block;

  /* Keep everything in the title in a single line */
  white-space: nowrap;

}