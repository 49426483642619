.sdk-iframe-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
}

.sdk-iframe-container iframe {
  height: 100%;
  width: 100%;
  border: 0;
}
