$aspect_ratio: calc(16 / 9);
$base_width: 1040px;
$base_height: calc($base_width / $aspect_ratio);
.scene-renderer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.scene-renderer .scene-renderer-content {
  width: $base_width;
  height: $base_height;
  display: block;
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  // background-color: rgba(255, 192, 203, 0.16);
  // border: 1px solid purple;
  // transform: scale(calc(100vw / $base_width));
}

.scene-renderer-controller {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.scene-renderer .timer-container {
  position: absolute;
  z-index: 99999;
}
