.background-elements-container {
  position: absolute;
  top: 0;
  bottom: -100px;
  left: 0;
  right: 0;
  z-index: -10;
  overflow: hidden;
  background: linear-gradient(
    168.47deg,
    #fbff35 -7.63%,
    #ffa6f1 12.27%,
    #3905f5 104.74%
  );
}

.background-elements-container > * {
  position: absolute;
}

.background-elements-container .ball-yellow {
  background-image: url("./bg/ball-yellow.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 225px;
  height: 254px;
}

.background-elements-container .bar-pink {
  background-image: url("./bg/bar-pink.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 315px;
  height: 253px;
}

.background-elements-container .triangle {
  background-image: url("./bg/triangle.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 2400px;
  height: 2500px;
  background-position: top left;
  bottom: -1450px;
  right: -1030px;
}
